import React from "react"
 import { graphql } from 'gatsby' 
import ReactHtmlParser from 'react-html-parser';

import SEO from "../../utils/seo"
import Layout from "../../layout/layout"
import ContentContainer from "../../components/ContentContainer"  

export default function contactPage({data}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>      
       <SEO title={frontmatter.title} />       
      <ContentContainer heading={frontmatter.title} headline={frontmatter.headline}>
        <div><img src={frontmatter.featured_image} alt="" width="100%" /></div>
        {ReactHtmlParser(html)}
      </ContentContainer>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {        
        path
        title
        headline
        featured_image
      }
    }
  }
`